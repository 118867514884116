<script lang="ts">
  import { onMount } from "svelte";
  import AutoGrowTextarea from "../common/AutoGrowTextarea.svelte";
  import Badge from "../common/Badge.svelte";

  interface Props {
    typefaceName: string;
  }

  const DISPLAY_TEXT = "IS ANYONE OUT THERE?";
  const TYPE_SPEED = 200;

  let { typefaceName }: Props = $props();
  let text = $state("");
  let active = $state(false);
  // svelte-ignore non_reactive_update
  let textareaRef: HTMLTextAreaElement;

  const activate = () => {
    active = true;
    textareaRef.focus();
    let i = 0;
    const interval = setInterval(() => {
      if (i < DISPLAY_TEXT.length) {
        text += DISPLAY_TEXT[i];
        i++;
      } else {
        clearInterval(interval);
      }
    }, TYPE_SPEED);
  };

  onMount(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !active) {
            activate();
          }
        });
      },
      {
        threshold: 1,
      },
    );
    observer.observe(textareaRef);
    return () => observer.disconnect();
  });
</script>

<div class="background">
  <div class="content-wrapper">
    <a href="/typefaces/erika" class="title-wrapper">
      <div class="title">G2 Erika</div>
    </a>
    <div class="badge-wrapper">
      <Badge --badge-scale="1.5">New Styles!</Badge>
    </div>
    <div class="textarea-wrapper">
      <AutoGrowTextarea
        --font-size="var(--typewriter-font-size)"
        --typetester-font={`"${typefaceName}", "Blank"`}
        --typetester-text-align="center"
        bind:ref={textareaRef}
        bind:value={text}
      ></AutoGrowTextarea>
    </div>
  </div>
</div>

<style>
  .background {
    position: relative;
    width: 100%;
    height: 200vh;
  }

  .textarea-wrapper{
    max-height: 100vh;
    overflow: auto;
  }

  .content-wrapper {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    --typewriter-font-size: 30vh;
    @media (max-width: 1200px) {
      --typewriter-font-size: 25vh;
    }
    @media (max-width: 990px) {
      --typewriter-font-size: 20vh;
    }
    @media (max-width: 700px) {
      --typewriter-font-size: 15vh;
    }
    @media (max-width: 500px) {
      --typewriter-font-size: 10vh;
    }
  }
  .title-wrapper {
    position: absolute;
    z-index: 5;
    top: var(--main-margin);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding: var(--main-margin);
    user-select: none;
    --webkit-user-select: none;
    &:hover {
      opacity: 1;
    }
  }

  .title {
    display: block;
    background: var(--bg-color);
    color: var(--text-color);
    border: var(--line-width) solid var(--text-color);
    padding: var(--button-padding-y) var(--button-padding-x);
    text-decoration: none;
    user-select: none;
    border-radius: 10px;

    &:hover {
      background: var(--text-color);
      color: var(--bg-color);
    }
  }

  .badge-wrapper {
    position: absolute;
    pointer-events: none;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 10;
    height: 600px;
  }
</style>
